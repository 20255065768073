import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultAsyncState } from ".";
import { DefaultPayload } from "src/dtos/DefaultDTO";
import { FormaPagamentoDTO, PayloadFormaPagamento } from "src/dtos/ConfigDTO";

// Definindo interfaces para o estado
interface AvaliacaoEntregadorState {
  loading: boolean;
  error: string | null;
  data: any | null;
}

interface WalletState {
  loading: boolean;
  error: string | null;
  data: any | null;
}

type TipoUsuario = "LICENCIADO" | "Representante" | "SAAS" |"";

interface ConfigState {
  loading: boolean;
  loadingEmAndamento: boolean;
  loadingMasterPage: boolean;
  error: string | null;
  emAndamento: any | null;
  empresaID: string;
  template: any;
  prioridade: any[];
  formaDePagamento: any[];
  masterPage: any;
  entregadores: any;
  loadingSituacao: boolean;
  respostaSituacao: string | null;
  ocorrencias: any[];
  orcamentos: any;
  avaliacao: any;
  avaliacaoEntregador: AvaliacaoEntregadorState;
  tempoMedio: any;
  usuarioPlano: string;
  resposta: string;
  tipoDeUsuario: TipoUsuario;
  tipoDeUsuarioLoading: boolean;
  empresasPick: any;
  respostaSalvar: string | null;
  errorSalvar: string | null;
  wallet: WalletState;
  tiposDeVeiculos: any;
  adicionarFinanceiro: DefaultAsyncState<any>;
  tipoDeCobranca: DefaultAsyncState<any>;
  tipoDePagamento: DefaultAsyncState<any>;
  salvarUsuario: DefaultAsyncState<any>;
  listaEntregaParceira: DefaultAsyncState<any>;
  solicitarEntregaParceira: DefaultAsyncState<any>;
  hubspotToken: DefaultAsyncState<any>;
  ifoodMerchantIds: DefaultAsyncState<any>;
  formasPagamento: DefaultAsyncState<FormaPagamentoDTO>;
}

const initialValues = {
  loading: false,
  data: null,
  error: null,
};

// Estado inicial
const initialState: ConfigState = {
  loading: false,
  loadingEmAndamento: false,
  loadingMasterPage: false,
  error: null,
  emAndamento: null,
  empresaID: "",
  template: {},
  prioridade: [],
  formaDePagamento: [],
  masterPage: {},
  entregadores: {},
  loadingSituacao: false,
  respostaSituacao: "",
  ocorrencias: [],
  orcamentos: {},
  avaliacao: {},
  avaliacaoEntregador: {
    loading: false,
    error: null,
    data: null,
  },
  tempoMedio: {},
  usuarioPlano: "",
  resposta: "",
  tipoDeUsuario: "",
  tipoDeUsuarioLoading: true,
  empresasPick: [],
  respostaSalvar: null,
  errorSalvar: null,
  wallet: {
    loading: false,
    error: null,
    data: null,
  },
  tiposDeVeiculos: [],
  adicionarFinanceiro: {
    loading: false,
    error: null,
    data: null,
  },
  tipoDeCobranca: {
    loading: false,
    error: null,
    data: null,
  },
  tipoDePagamento: {
    loading: false,
    error: null,
    data: null,
  },
  salvarUsuario: {
    loading: false,
    error: null,
    data: null,
  },
  listaEntregaParceira: {
    loading: false,
    error: null,
    data: null,
  },
  solicitarEntregaParceira: {
    loading: false,
    error: null,
    data: null,
  },
  hubspotToken: {
    loading: false,
    error: null,
    data: null,
  },
  ifoodMerchantIds: {
    loading: false,
    error: null,
    data: null,
  },
  formasPagamento: { ...initialValues },
};

// Slice
export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    configStoreInitialState(state) {
      state = { ...initialState };
    },
    fetchMasterPageStart(state) {
      state.loadingMasterPage = true;
      state.error = null;
    },
    fetchMasterPageSuccess(state, action: PayloadAction<any>) {
      state.loadingMasterPage = false;
      state.masterPage = action.payload;
      state.empresaID = action.payload.empresa?.empresaID;
      state.usuarioPlano = action.payload.pickSelecionada?.planoAssinaturaID;
    },
    fetchMasterPageFailure(state, action: PayloadAction<string>) {
      state.loadingMasterPage = false;
      state.error = action.payload;
    },
    emAndamentoStart(state, action: PayloadAction<{ empresaID: string }>) {
      state.loadingEmAndamento = true;
      state.error = null;
    },
    emAndamentoSuccess(state, action: PayloadAction<any>) {
      state.loadingEmAndamento = false;
      state.emAndamento = action.payload;
    },
    emAndamentoFailure(state, action: PayloadAction<string>) {
      state.loadingEmAndamento = false;
      state.error = action.payload;
    },

    buscarTemplateRequest(state) {
      state.loading = true;
    },
    buscarTemplateSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.template = action.payload;
    },
    buscarTemplateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    listarRequest(state) {
      state.loading = true;
    },
    listarSuccess(state, action: PayloadAction<any[]>) {
      state.loading = false;
      state.prioridade = action.payload[0].lista;
      state.formaDePagamento = action.payload[1].lista;
    },
    listarFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    listarEntregadoresRequest(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    listarEntregadoresSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.entregadores = action.payload.dados;
    },
    listarEntregadoresFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    alterarSituacaoRequest(
      state,
      action: PayloadAction<{ pedidoID: string; situacao: string }>
    ) {
      state.loading = true;
      state.loadingSituacao = true;
    },
    alterarSituacaoSuccess(state, action: PayloadAction<{ mensagem: string }>) {
      state.loadingSituacao = false;
      state.loading = false;
      state.respostaSituacao = action.payload.mensagem;
    },
    alterarSituacaoFailure(state, action: PayloadAction<string>) {
      state.loadingSituacao = false;
      state.loading = false;
      state.error = action.payload;
    },
    limparResposta(state) {
      state.respostaSituacao = null;
      state.respostaSalvar = null;
      state.errorSalvar = null;
      state.error = null;
      state.salvarUsuario = { ...initialState.salvarUsuario };
    },
    registrarOcorrencia(state) {
      state.loading = true;
    },
    ocorrenciaSuccess(state) {
      state.loading = false;
    },
    ocorrenciaFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    listarOcorrenciaRequest(state) {
      state.loading = true;
    },
    listarOcorrenciaSuccess(state, action: PayloadAction<any[]>) {
      state.loading = false;
      state.ocorrencias = action.payload;
    },
    listarOcorrenciaFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    listarEntregaParceira(state, action: PayloadAction<string>) {
      state.listaEntregaParceira.loading = true;
      state.listaEntregaParceira.error = null;
    },
    entregaParceiraSuccess(state, action: PayloadAction<{ orcamentos: any }>) {
      state.listaEntregaParceira.loading = false;
      state.listaEntregaParceira.data = action.payload;
    },
    entregaParceiraFailure(state, action: PayloadAction<string>) {
      state.listaEntregaParceira.loading = false;
      state.listaEntregaParceira.error = action.payload;
    },
    solicitarEntregaParceiraStart(state, action: PayloadAction<any>) {
      state.solicitarEntregaParceira.loading = true;
      state.solicitarEntregaParceira.error = null;
    },
    solicitarEntregaParceiraSuccess(state, action: PayloadAction<any>) {
      state.solicitarEntregaParceira.loading = false;
      state.solicitarEntregaParceira.data = action.payload;
    },
    solicitarEntregaParceiraFailure(state, action: PayloadAction<string>) {
      state.solicitarEntregaParceira.loading = false;
      state.solicitarEntregaParceira.error = action.payload;
    },
    limparEntregaParceira(state) {
      state.solicitarEntregaParceira.loading = false;
      state.solicitarEntregaParceira.error = null;
      state.solicitarEntregaParceira.data = null;
    },
    listarAvaliacao(state) {
      state.loading = true;
      state.error = null;
    },
    avaliacaoSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.avaliacao = action.payload;
    },
    avaliacaoFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    avaliarEntregadorReq(
      state,
      action: PayloadAction<{ pedidoID: string; nota: number }>
    ) {
      state.avaliacaoEntregador.loading = true;
      state.avaliacaoEntregador.error = null;
    },
    avaliarEntregadorSuccess(state, action: PayloadAction<any>) {
      state.avaliacaoEntregador.loading = false;
      state.avaliacaoEntregador.data = action.payload;
    },
    avaliarEntregadorFailure(state, action: PayloadAction<string>) {
      state.avaliacaoEntregador.loading = false;
      state.avaliacaoEntregador.error = action.payload;
    },
    tempoMedioRequest(state) {
      state.loading = true;
    },
    tempoMedioSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.tempoMedio = action.payload;
    },
    tempoMedioFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    salvarUsuarioReq(state) {
      state.salvarUsuario.loading = true;
      state.salvarUsuario.error = null;
      state.salvarUsuario.data = null;
    },
    salvarUsuarioSuccess(state, action: PayloadAction<any>) {
      state.salvarUsuario.loading = false;
      state.salvarUsuario.error = null;
      state.salvarUsuario.data = action.payload;
    },
    salvarUsuarioFailure(state, action: PayloadAction<string>) {
      state.salvarUsuario.loading = false;
      state.salvarUsuario.error = action.payload;
      state.salvarUsuario.data = null;
    },
    limparSalvarUsuario(state) {
      state.salvarUsuario.data = null;
      state.salvarUsuario.error = null;
    },
    definirTipoDeUsuario(
      state,
      action: PayloadAction<{ tipo: string; empresas: object[] }>
    ) {
      state.tipoDeUsuario = action.payload.tipo as TipoUsuario;
      state.tipoDeUsuarioLoading = false;
      state.empresasPick = action.payload.empresas;
    },
    limparTipoDeUsuario(state) {
      state.tipoDeUsuario = "";
    },
    listarEmpresasPick(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    empresasPickSuccess(state, action: PayloadAction<any[]>) {
      state.loading = false;
      state.empresasPick = action.payload;
    },
    empresasPickFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    adicionarFinanceiroStart(state) {
      state.adicionarFinanceiro.loading = true;
      state.adicionarFinanceiro.data = null;
      state.adicionarFinanceiro.error = null;
    },
    adicionarFinanceiroSuccess(state, action: PayloadAction<string>) {
      state.adicionarFinanceiro.loading = false;
      state.adicionarFinanceiro.error = null;
      state.adicionarFinanceiro.data = action.payload;
    },
    adicionarFinanceiroFailure(state, action: PayloadAction<string>) {
      state.adicionarFinanceiro.loading = false;
      state.adicionarFinanceiro.data = null;
      state.adicionarFinanceiro.error = action.payload;
    },
    limparAdicionarFinanceiro(state) {
      state.adicionarFinanceiro.loading = false;
      state.adicionarFinanceiro.data = null;
      state.adicionarFinanceiro.error = null;
    },
    walletStart(state) {
      state.wallet.loading = true;
      state.wallet.error = null;
      state.wallet.data = null;
    },
    walletSuccess(state, action: PayloadAction<any>) {
      state.wallet.loading = false;
      state.wallet.error = null;
      state.wallet.data = action.payload;
    },
    walletFailure(state, action: PayloadAction<string>) {
      state.wallet.loading = false;
      state.wallet.data = null;
      state.wallet.error = action.payload;
    },
    excluirEntregador(state, action) {
      state.loading = true;
      state.errorSalvar = null;
    },
    excluirEntregadorSuccess(
      state,
      action: PayloadAction<{ mensagem: string }>
    ) {
      state.loading = false;
      state.respostaSalvar = action.payload.mensagem;
    },
    excluirEntregadorFailure(
      state,
      action: PayloadAction<{ mensagem: string }>
    ) {
      state.loading = false;
      state.errorSalvar = action.payload.mensagem;
    },
    listarTipoDeVeiculoStart(state, action: PayloadAction<DefaultPayload>) {
      state.loading = true;
      state.error = null;
    },
    tipoDeVeiculoSuccess(state, action: PayloadAction<any[]>) {
      state.loading = false;
      state.tiposDeVeiculos = action.payload;
    },
    tipoDeVeiculoFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    definirTipoDeCobrancaStart(state, action: any) {
      state.tipoDeCobranca.loading = true;
      state.tipoDeCobranca.error = null;
    },
    definirTipoDeCobrancaSuccess(state, action: PayloadAction<any[]>) {
      state.tipoDeCobranca.loading = false;
      state.tipoDeCobranca.data = action.payload;
    },
    definirTipoDeCobrancaFailure(state, action: PayloadAction<string>) {
      state.tipoDeCobranca.loading = false;
      state.tipoDeCobranca.error = action.payload;
    },
    limparTipoDeCobranca(state) {
      state.tipoDeCobranca.loading = false;
      state.tipoDeCobranca.data = null;
      state.tipoDeCobranca.error = null;
    },
    definirTipoDePagamentoStart(state, action: any) {
      state.tipoDePagamento.loading = true;
      state.tipoDePagamento.error = null;
    },
    definirTipoDePagamentoSuccess(state, action: PayloadAction<any[]>) {
      state.tipoDePagamento.loading = false;
      state.tipoDePagamento.data = action.payload;
    },
    definirTipoDePagamentoFailure(state, action: PayloadAction<string>) {
      state.tipoDePagamento.loading = false;
      state.tipoDePagamento.error = action.payload;
    },
    limparTipoDePagamento(state) {
      state.tipoDePagamento.loading = false;
      state.tipoDePagamento.data = null;
      state.tipoDePagamento.error = null;
    },
    getHubspotTokenStart(state) {
      state.hubspotToken.loading = true;
      state.hubspotToken.error = null;
    },
    getHubspotTokenSuccess(state, action: PayloadAction<any>) {
      state.hubspotToken.loading = false;
      state.hubspotToken.data = action.payload;
    },
    getHubspotTokenFailure(state, action: PayloadAction<string>) {
      state.hubspotToken.loading = false;
      state.hubspotToken.error = action.payload;
    },
    getIfoodMerchantIdsStart: (state) => {
      state.ifoodMerchantIds.loading = true;
      state.ifoodMerchantIds.data = null;
      state.ifoodMerchantIds.error = null;
    },
    getIfoodMerchantIdsSuccess: (state, action) => {
      state.ifoodMerchantIds.loading = false;
      state.ifoodMerchantIds.data = action.payload;
      state.ifoodMerchantIds.error = null;
    },
    getIfoodMerchantIdsFailure: (state, action) => {
      state.ifoodMerchantIds.loading = false;
      state.ifoodMerchantIds.data = null;
      state.ifoodMerchantIds.error = action.payload;
    },
    limparIfoodMerchantIds: (state) => {
      state.ifoodMerchantIds = initialState.ifoodMerchantIds;
    },
    listarFormasPagamentoStart: (
      state,
      action: PayloadAction<PayloadFormaPagamento>
    ) => {
      state.formasPagamento.loading = true;
      state.formasPagamento.data = null;
      state.formasPagamento.error = null;
    },
    listarFormasPagamentoSuccess: (state, action) => {
      state.formasPagamento.loading = false;
      state.formasPagamento.data = action.payload;
      state.formasPagamento.error = null;
    },
    listarFormasPagamentoFailure: (state, action) => {
      state.formasPagamento.loading = false;
      state.formasPagamento.data = null;
      state.formasPagamento.error = action.payload;
    },
    listarFormasPagamentoClear: (state) => {
      state.formasPagamento = { ...initialState.formasPagamento };
    },
  },
});

// Exportando actions e reducer
export const {
  configStoreInitialState,
  fetchMasterPageStart,
  fetchMasterPageSuccess,
  fetchMasterPageFailure,
  emAndamentoStart,
  emAndamentoSuccess,
  emAndamentoFailure,
  buscarTemplateRequest,
  buscarTemplateSuccess,
  buscarTemplateFailure,
  listarRequest,
  listarSuccess,
  listarFailure,
  listarEntregadoresRequest,
  listarEntregadoresSuccess,
  listarEntregadoresFailure,
  alterarSituacaoRequest,
  alterarSituacaoSuccess,
  alterarSituacaoFailure,
  limparResposta,
  registrarOcorrencia,
  ocorrenciaSuccess,
  ocorrenciaFailure,
  listarOcorrenciaRequest,
  listarOcorrenciaSuccess,
  listarOcorrenciaFailure,
  listarEntregaParceira,
  entregaParceiraSuccess,
  entregaParceiraFailure,
  solicitarEntregaParceiraFailure,
  solicitarEntregaParceiraStart,
  solicitarEntregaParceiraSuccess,
  limparEntregaParceira,
  listarAvaliacao,
  avaliacaoSuccess,
  avaliacaoFailure,
  avaliarEntregadorReq,
  avaliarEntregadorSuccess,
  avaliarEntregadorFailure,
  tempoMedioRequest,
  tempoMedioSuccess,
  tempoMedioFailure,
  salvarUsuarioReq,
  salvarUsuarioSuccess,
  salvarUsuarioFailure,
  limparSalvarUsuario,
  definirTipoDeUsuario,
  limparTipoDeUsuario,
  listarEmpresasPick,
  empresasPickSuccess,
  empresasPickFailure,
  adicionarFinanceiroStart,
  adicionarFinanceiroSuccess,
  adicionarFinanceiroFailure,
  limparAdicionarFinanceiro,
  walletStart,
  walletSuccess,
  walletFailure,
  excluirEntregador,
  excluirEntregadorSuccess,
  excluirEntregadorFailure,
  listarTipoDeVeiculoStart,
  tipoDeVeiculoFailure,
  tipoDeVeiculoSuccess,
  definirTipoDeCobrancaFailure,
  definirTipoDeCobrancaStart,
  definirTipoDeCobrancaSuccess,
  limparTipoDeCobranca,
  definirTipoDePagamentoFailure,
  definirTipoDePagamentoStart,
  definirTipoDePagamentoSuccess,
  limparTipoDePagamento,
  getHubspotTokenFailure,
  getHubspotTokenStart,
  getHubspotTokenSuccess,
  getIfoodMerchantIdsStart,
  getIfoodMerchantIdsFailure,
  getIfoodMerchantIdsSuccess,
  limparIfoodMerchantIds,
  listarFormasPagamentoStart,
  listarFormasPagamentoSuccess,
  listarFormasPagamentoFailure,
  listarFormasPagamentoClear,
} = configSlice.actions;

export default configSlice;
