import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ModalHandleVagaPadrao from 'src/components/VagaPadrao/ModalHandleVagaPadrao';
import TabelaVagaPadrao from 'src/components/VagaPadrao/TabelaVagaPadrao';
import { DynamicDataContainer } from 'src/ds';
import { DynamicDataContainerRef } from 'src/ds/components/DynamicDataContainer';
import { Empresa } from 'src/dtos/EmpresasDTO';
import { RootState } from 'src/store/reducers';

const VagaPadrao: React.FC = () => {
    const vagaPadraoData = useSelector((state: RootState) => state.escalas.vagaPadrao.data);
    const loading = useSelector((state: RootState) => state.escalas.vagaPadrao.loading);
    const hookForm = useForm();
    const cardRef = useRef<DynamicDataContainerRef>(null);
    const [modalCadastrar, setModalCadastrar] = useState(false);
    const empresas = useSelector((state: RootState) => state.config.empresasPick?.dados?.lista);
    const filteredBase = empresas?.filter((empresa: Empresa) => empresa.ehBase === false);
    const [empresasOptions, setEmpresasOptions] = useState<{ label: string; value: string | number }[]>([]);

    useEffect(() => {
        if (Array.isArray(filteredBase)) {
            const mappedOptions = filteredBase.map((empresa: Empresa) => ({
                label: empresa.nome,
                value: empresa.empresaID,
            }));
            setEmpresasOptions(mappedOptions);
        } else {
            setEmpresasOptions([]);
        }
    }, [filteredBase]);

    useEffect(() => {
        hookForm.reset({
            paginacao: 20,
            pagina: 1,
            ordenacaoOrdem: '',
            ordenacaoCampo: '',
        });
    }, [hookForm])

    function refreshTable() {
        cardRef.current?.onSubmit();
    }

    return (
        <>
            <DynamicDataContainer
                ref={cardRef}
                hookForm={hookForm}
                cardFormFiltrosProps={{
                    dispatchMethods: {
                        actionName: 'vagaPadraoStart'
                    },
                    fields: [
                        { label: "Buscar", name: "filtros", nameFiltro: 'nome', ehFiltro: true, tipoFiltro: 'TEXTO' },
                        {
                            label: "Empresas",
                            name: 'empresasVinculadas',
                            type: 'autocompleteMultiple',
                            options: empresasOptions,
                            valueKey: 'value',
                            labelKey: 'label',
                            ehFiltro: true,
                            filtroCustomizado: (nome, valor: unknown) => {
                                let filtro: string = '';
                                console.log(nome, valor)
                                if (Array.isArray(valor)) {
                                    const valores = valor
                                        .map((empresa) => `(${nome}.Any(empresaID.toString().Equals("${empresa.value}")))`)
                                        .join(' || ')
                                    filtro = `(${valores})`
                                }
                                return filtro
                            },
                            fullWidth: true,
                        },
                    ],
                    loading: loading,
                }}
                sections={[
                    {
                        title: "Vagas padrão",
                        label: "tabela",
                        tableNavigation: {
                            indiceFinal: vagaPadraoData?.dados?.indiceFinal,
                            indiceTotal: vagaPadraoData?.dados?.total,
                            indiceInicial: vagaPadraoData?.dados?.indiceFinal,
                            ultimaPagina: vagaPadraoData?.dados?.ultimaPagina
                        },
                        extraButtons: [{
                            label: 'Cadastrar',
                            action: () => setModalCadastrar(true),
                        }],
                        component: <TabelaVagaPadrao
                            hookForm={hookForm}
                            onSubmit={() => cardRef.current?.onSubmit()}
                            fezRequisicao={cardRef.current?.fezRequisicao}
                            callback={refreshTable}
                        />,
                    }
                ]}
            />
            {modalCadastrar && (
                <ModalHandleVagaPadrao
                    open={modalCadastrar}
                    onClose={() => setModalCadastrar(false)}
                    callback={refreshTable}
                />
            )}
        </>
    )
}

export default VagaPadrao