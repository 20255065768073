import React, { useState } from 'react';
import {
    Box,
    Table,
    TableCell,
    TableRow,
    Typography,
    TableHead,
    TableBody,
    TableContainer,
    Paper,
    Button,
    Card,
    Stack,
    Fade,
    ClickAwayListener,
    Popper,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Pedido } from 'src/dtos/PedidosDTO';
import { RootState } from 'src/store/reducers';
import { CustomTableHead, CustomTableRow } from './components/TablePerformance';
import { PLANO_BRENDI, PLANO_FREEMIUM, sanitizeSituacao } from 'src/ds';
import ModalTrocarStatus from '../ModalTrocarStatus';

const PainelPerformance = () => {
    const emAndamento = useSelector((state: RootState) => state.config?.emAndamento?.dados?.lista);
    const template = useSelector((state: RootState) => state.config?.template?.template);
    const usuarioPlano = useSelector((state: RootState) => state.config?.usuarioPlano);
    const tipoDeUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);
    const [anchorSituacao, setAnchorSituacao] = useState(null);
    const openModalStatus = Boolean(anchorSituacao);

    const pedidosAgrupados = emAndamento?.reduce((acc: Record<string, Pedido[]>, pedido: Pedido) => {
        const situacao = pedido.situacao || 'Outros';
        if (!acc[situacao]) {
            acc[situacao] = [];
        }
        acc[situacao].push(pedido);
        return acc;
    }, {});

    const headers = [
        'Origem',
        'Código',
        'Empresa',
        'Cliente',
        'Destino',
        'Entregador',
        'Pagamento',
        'Situação',
        'Tempo',
        'Ações',
    ];

    const toggleModalStatus = (e: any) => {
        if (usuarioPlano === PLANO_FREEMIUM || usuarioPlano === PLANO_BRENDI || usuarioPlano === '00000000-0000-0000-0000-000000000000') return;
        if (tipoDeUsuario !== 'Representante') {
            setAnchorSituacao((prev) => (prev ? null : e.currentTarget));
        }
    };

    function closePopper(event?: Event) {
        if (event) {
            // Verifica se o clique foi no anchorEl para evitar fechamento imediato
            if (anchorSituacao && (anchorSituacao as HTMLElement).contains(event.target as Node)) {
                return;
            }
        }
        console.log('closePopper');
        setAnchorSituacao(null);
    }

    return (
        <Box>
            {pedidosAgrupados &&
                Object.keys(pedidosAgrupados).map((situacao) => (
                    <Card
                        key={situacao}
                        sx={{ my: 4, backgroundColor: 'white', padding: '16px', borderRadius: '8px', boxShadow: 'none', border: '1px solid #ccc' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '16px',
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                {situacao.toUpperCase()}
                            </Typography>

                        </Box>

                        <TableContainer
                            component={Paper}
                            sx={{
                                borderRadius: '8px',
                                overflow: 'hidden',
                                backgroundColor: 'white',
                            }}
                        >
                            <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>

                                <CustomTableHead headers={headers} />
                                <TableBody>
                                    {pedidosAgrupados[situacao].map((pedido: Pedido, index: number) => (
                                        <>
                                            <CustomTableRow
                                                key={index}
                                                pedido={pedido}
                                                isLast={index === pedidosAgrupados[situacao].length - 1}
                                                template={template}
                                                toggleModalStatus={toggleModalStatus}
                                            />
                                            <Popper
                                                id={'simple-popper1'}
                                                open={openModalStatus}
                                                anchorEl={anchorSituacao}
                                                sx={{ zIndex: 10000 }}
                                                placement="bottom-end"
                                                modifiers={
                                                    [
                                                        {
                                                            name: 'flip',
                                                            enabled: true,
                                                            options: {
                                                                altBoundary: true,
                                                                rootBoundary: 'viewport',
                                                                padding: 8,
                                                            },
                                                        },
                                                        {
                                                            name: 'preventOverflow',
                                                            enabled: true,
                                                            options: {
                                                                boundary: 'viewport',
                                                                altAxis: true,
                                                            },
                                                        },
                                                    ]}
                                                transition
                                            >
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={200} >
                                                        <Box>
                                                            <ClickAwayListener onClickAway={() => { setAnchorSituacao(null) }}>
                                                                <ModalTrocarStatus
                                                                    visible={openModalStatus}
                                                                    // @ts-expect-error
                                                                    onClose={toggleModalStatus}
                                                                    pedido={pedido}
                                                                    closePopper={closePopper}
                                                                    statusAtual={sanitizeSituacao(pedido)}
                                                                />
                                                            </ClickAwayListener>
                                                        </Box>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                ))}

        </Box>
    );
};

export default PainelPerformance;
