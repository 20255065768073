import { useForm } from "react-hook-form";
import TabelaEmpresas from "../../components/Empresas/TabelaEmpresas";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { DynamicDataContainer } from "src/ds";
import { listaEmpresasStart } from "src/store/reducers/empresa";
import { Empresa } from "src/dtos/EmpresasDTO";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const Empresas = () => {
    const empresasDados = useSelector((state: any) => state.empresas.listaEmpresas?.data);
    const empresas = empresasDados?.lista?.filter((empresa: Empresa) => !empresa.ehBase);
    const loadingEmpresas = useSelector((state: any) => state.empresas.listaEmpresas.loading);

    const hookForm = useForm({
        defaultValues: {
            nomeEmpresa: '',
            codigoIdentificador: '',
            dataCadastroInicial: '',
            dataCadastroFinal: '',
            cnpj: '',
            ativo: true,
            paginacao: 20,
            pagina: 1,
        },
    });
    const dispatch = useDispatch();
    const primeiraRenderizacao = useRef(true);
    const cardRef = useRef<DynamicDataContainerRef>(null)
    const { watch } = hookForm
    useEffect(() => {
        if (primeiraRenderizacao.current === true) {
            cardRef.current?.onSubmit()
            primeiraRenderizacao.current = false
            return
        }
    }, [primeiraRenderizacao]);

    useEffect(() => {
        dispatch(listaEmpresasStart({
            ...hookForm.getValues(),
        }));
    }, [watch("paginacao"), watch("pagina")]);

    return (
        <DynamicDataContainer
            ref={cardRef}
            hookForm={hookForm}
            cardFormFiltrosProps={{
                dispatchMethods: {
                    actionName: 'listaEmpresasStart'
                },
                fields: [
                    { label: "Buscar", name: "nomeEmpresa" },
                    {
                        label: "Tags",
                        name: "tagsFiltro",
                        type: "tags",
                        filtroInline: true,
                    },
                ],
                loading: loadingEmpresas
            }}
            sections={[
                {
                    title: 'Empresas',
                    label: 'Empresas',
                    tableNavigation: {
                        indiceInicial: empresasDados?.indiceInicial,
                        indiceFinal: empresasDados?.indiceFinal,
                        indiceTotal: empresasDados?.total,
                        ultimaPagina: empresasDados?.ultimaPagina
                    },
                    component:
                        <TabelaEmpresas
                            empresas={empresas}
                            loading={loadingEmpresas}
                            fezRequisicao={cardRef.current?.fezRequisicao}
                        />
                }
            ]}
        />
    );
};

export default Empresas;
