import { useState, useEffect } from 'react';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { Badge, BottomNavigation, BottomNavigationAction, Button, Paper, Tooltip, useTheme } from '@mui/material';
import ModalEscalas from '../ModalEscalas';
import { useSelector } from 'react-redux';
import { MonetizationOnOutlined, PriceCheck, SmartToyOutlined, VolumeOff, VolumeUp } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { PLANO_BRENDI, PLANO_FREEMIUM, useAudio, useResponsive } from 'src/ds';
import { RootState } from 'src/store/reducers';

const MenuFooter = ({ abrirChat, abrirAvaliar, abrirTempoMedio, abrirFinanceiro, abrirRobo, abrirTaxaExtra, abrirFreemium, msgsNaoLidas }: any) => {
    const notificacao = 'https://cdn.pixabay.com/audio/2024/10/25/audio_66d1f8e5fd.mp3'
    const { permiteSom, solicitarPermissaoSom, definirFonteAudio, tocarAudio, audioRef } = useAudio(notificacao);

    useEffect(() => {
        definirFonteAudio(notificacao);
    }, [notificacao, definirFonteAudio]);

    const usuarioPlano = useSelector((state: RootState) => state.config.usuarioPlano);
    const tipoDeUsuario = useSelector((state: RootState) => state.config.tipoDeUsuario);
    const podeChat1 = useSelector((state: RootState) => state.config.masterPage?.empresa?.podeVisualizarChat);
    const podeEscalas1 = useSelector((state: RootState) => state.config.masterPage?.empresa?.podeVisualizarEscalas);
    const podeTemposMedios1 = useSelector((state: RootState) => state.config.masterPage?.empresa?.podeVisualizarTempos);
    const theme = useTheme();
    const [value, _] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const handleClose = () => setOpenModal(false);
    const { isMobile } = useResponsive();

    const podeChat = tipoDeUsuario === 'SAAS' ? true : podeChat1;
    const podeEscalas = tipoDeUsuario === 'SAAS' ? true : podeEscalas1;
    const podeTemposMedios = tipoDeUsuario === 'SAAS' ? true : podeTemposMedios1;

    const [prevMsgsNaoLidas, setPrevMsgsNaoLidas] = useState(msgsNaoLidas);

    useEffect(() => {
        if (msgsNaoLidas > prevMsgsNaoLidas) {
            if (audioRef.current) {
                tocarAudio();
            }
        }
        setPrevMsgsNaoLidas(msgsNaoLidas);
    }, [msgsNaoLidas, prevMsgsNaoLidas]);

    return (
        <>
            {!permiteSom &&
                <Tooltip title="Ativar notificações" arrow>
                    <Button
                        onClick={solicitarPermissaoSom}
                        sx={{ width: 'min-content', position: 'absolute', bottom: 70, left: 0 }}
                        variant="text"
                    >
                        <VolumeOff />
                    </Button>
                </Tooltip>
            }

            <audio ref={audioRef} preload="none" />
            <Paper
                sx={{
                    width: '100%',
                    minHeight: 'min-content',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${theme.palette.primary.main} transparent`,
                    '&::-webkit-scrollbar': {
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '10px',
                        border: `2px solid ${theme.palette.background.paper}`,
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                        borderRadius: '10px'
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: theme.palette.primary.dark,
                    },
                    '&::-webkit-scrollbar-thumb:active': {
                        backgroundColor: theme.palette.primary.light,
                    }
                }}
            >
                <BottomNavigation
                    showLabels
                    value={value}
                    component='footer'
                    sx={{
                        p: isMobile ? '1rem 0' : '1rem 0 0.5rem 0',
                        boxSizing: 'border-box',
                        minWidth: 'max-content',
                        maxWidth: '100%',
                        overflowY: 'hidden',
                        height: 'fit-content',
                        alignItems: 'center',
                        boxShadow: '0px -5px 20px 0px #0000001A',
                    }}
                >
                    {(podeChat || tipoDeUsuario === 'SAAS') && (
                        <BottomNavigationAction
                            label="Chat"
                            icon={
                                msgsNaoLidas > 0 ?
                                    <Badge
                                        badgeContent={msgsNaoLidas}
                                        color="primary"
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                top: '-10px',
                                                right: '-10px',
                                                position: 'absolute',
                                                transform: 'none',
                                            },
                                        }}
                                    >
                                        <ChatBubbleOutlineRoundedIcon />
                                    </Badge> : <ChatBubbleOutlineRoundedIcon />
                            }
                            onClick={abrirChat}
                            sx={{
                                color: value === 0 ? 'primary.main' : 'grey.500',
                                '& .MuiSvgIcon-root': {
                                    color: value === 0 ? 'primary.main' : 'primary.main',
                                },
                            }}
                        />
                    )}

                    {tipoDeUsuario === 'Representante' && (
                        <BottomNavigationAction
                            label="Avaliar Pedidos"
                            icon={<StarBorderRoundedIcon />}
                            onClick={abrirAvaliar}
                            sx={{
                                color: value === 1 ? 'primary.main' : 'grey.500',
                                '& .MuiSvgIcon-root': {
                                    color: value === 1 ? 'primary.main' : 'primary.main',
                                }
                            }}
                        />
                    )}

                    {podeEscalas && <BottomNavigationAction
                        label="Escalas"
                        icon={<CalendarMonthOutlinedIcon />}
                        sx={{
                            color: value === 2 ? 'primary.main' : 'grey.500',
                            '& .MuiSvgIcon-root': {
                                color: value === 2 ? 'primary.main' : 'primary.main',
                            }
                        }}
                        onClick={() => {
                            if (usuarioPlano === PLANO_FREEMIUM || usuarioPlano === PLANO_BRENDI || usuarioPlano === '00000000-0000-0000-0000-000000000000') {
                                abrirFreemium();
                            } else {
                                setOpenModal(true);
                            }
                        }}
                    />}

                    {(tipoDeUsuario === 'Representante' || tipoDeUsuario === 'SAAS') && podeTemposMedios && (
                        <BottomNavigationAction
                            label="Média"
                            icon={<AccessTimeRoundedIcon />}
                            onClick={abrirTempoMedio}
                            sx={{
                                color: value === 3 ? 'primary.main' : 'grey.500',
                                '& .MuiSvgIcon-root': {
                                    color: value === 3 ? 'primary.main' : 'primary.main',
                                }
                            }}
                        />
                    )}

                    {tipoDeUsuario === 'SAAS' && (
                        <BottomNavigationAction
                            label="Financeiro"
                            icon={<MonetizationOnOutlined />}
                            onClick={abrirFinanceiro}
                            sx={{
                                color: value === 4 ? 'primary.main' : 'grey.500',
                                '& .MuiSvgIcon-root': {
                                    color: value === 4 ? 'primary.main' : 'primary.main',
                                }
                            }}
                        />
                    )}

                    {tipoDeUsuario === 'SAAS' && (
                        <BottomNavigationAction
                            label="Roteirização"
                            icon={<SmartToyOutlined />}
                            onClick={abrirRobo}
                            sx={{
                                color: value === 5 ? 'primary.main' : 'grey.500',
                                '& .MuiSvgIcon-root': {
                                    color: value === 5 ? 'primary.main' : 'primary.main',
                                }
                            }}
                        />
                    )}

                    {tipoDeUsuario === 'SAAS' && (
                        <BottomNavigationAction
                            label="Taxa extra"
                            icon={<PriceCheck />}
                            onClick={abrirTaxaExtra}
                            sx={{
                                color: value === 6 ? 'primary.main' : 'grey.500',
                                '& .MuiSvgIcon-root': {
                                    color: value === 6 ? 'primary.main' : 'primary.main',
                                }
                            }}
                        />
                    )}
                </BottomNavigation>
            </Paper>
            {openModal &&
                <ModalEscalas open={openModal} handleClose={handleClose} />
            }
        </>
    );
};

export default MenuFooter;
