// @ts-nocheck
import { Box, Button, useTheme, Grid2, Stack, Typography, Tooltip } from "@mui/material";
import promo from '../../assets/promo.png';
import { MoreTime, SmartDisplay, } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { emAndamentoStart, limparEntregaParceira, listarEmpresasPick, listarEntregadoresRequest } from "../../store/reducers/config";
import './styles.css';
import CriarOcorrencia from "../../components/Painel/CriarOcorrencia";
import CriarPedido from "../../components/Painel/CriarPedido";
import MapHome from "../../components/Painel/MapHome";
import EntregaParceira from "../../components/Painel/EntregaParceira";
import ModalFiltros from "../../components/Painel/ModalFiltros";
import { usePedido } from "../../context/PedidoContext";
import AtribuirPedido from "../../components/Painel/AtribuirPedido";
import Chat from "../../components/Painel/Chat";
import { usePagina } from "../../context/PaginaContext";
import AvaliarPedidos from "../../components/Painel/AvaliarPedidos";
import TempoMedio from "../../components/Painel/TempoMedio";
import ModalContratarPlanos from "../../components/Painel/ModalContratarPlanos";
import ModalEntregadores from "../../components/Painel/ModalEntregadores";
import ModalBoasVindas from "../../components/Painel/ModalBoasVindas";
import ModalVideos from "../../components/Painel/ModalBoasVindas/ModalVideos";
import Financeiro from "../../components/Painel/Financeiro";
import Robo from "../../components/Painel/Robo";
import TaxaExtra from "../../components/Painel/TaxaExtra";
import { mudarSize, setAtualizarEmAndamento, toggleDevMode } from "../../store/reducers/utils";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { useResponsive, IconButtonFechar, unSanitizeSituacao, PLANO_FREEMIUM, PLANO_BRENDI } from "../../ds";
import { collection, query, orderBy, onSnapshot, where } from 'firebase/firestore';
import { db } from "src/services/firebase";
import PainelPerformance from "src/components/Painel/PainelPerformance";
import SlidePanel from "src/components/Painel/SlidePanel";
import ListaPedidos from "src/components/Painel/ListaPedidos";
import { useSnackbarContext } from "src/context/SnackbarContext";
import { useParams } from "react-router-dom";
import ReactConfetti from "react-confetti";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ModalTempoPronto from "src/components/Painel/ModalTempoPronto";

const Painel = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { isMobile } = useResponsive()
    const { selecionarPedido, markerSelecionado, markersSelecionados, selecionarMarker, roteando, adicionandoPedidos } = usePedido();
    const usuario = useSelector(state => state.login.usuario);
    const pickSelecionada = useSelector(state => state.config?.masterPage?.pickSelecionada);
    const emAndamento = useSelector(state => state.config?.emAndamento?.dados?.lista);
    const filtrosReducer = useSelector(state => state.utils.filtros);
    const [abrirFinanceiro, setAbrirFinanceiro] = useState(false);
    const [abrirRobo, setAbrirRobo] = useState(false);
    const [abrirTaxaExtra, setAbrirTaxaExtra] = useState(false);
    const [videos, setVideos] = useState(false);
    const [entregadores, setAbrirEntregadores] = useState(false);
    const [showEmAndamento, setShowEmAndamento] = useState(true);
    const [pedidoStatus, setPedidoStatus] = useState(null);
    const [criarPedido, setCriarPedido] = useState(false);
    const [abrirOcorrencia, setAbrirOcorrencia] = useState(false);
    const [atribuirPedido, setAtribuirPedido] = useState(false);
    const [abrirParceira, setAbrirParceira] = useState(false);
    const [abrirChatLateral, setAbrirChatLateral] = useState(false);
    const [abrirAvaliar, setAbrirAvaliar] = useState(false);
    const [abrirTempoMedio, setAbrirTempoMedio] = useState(false);
    const [editarPedido, setEditarPedido] = useState(null);
    const [addFiltros, setAddFiltros] = useState(false);
    const [filtros, setFiltros] = useState({});
    const [position, setPosition] = useState({ latitude: -12.7797200, longitude: -41.9297200 })
    const [searchTerm, setSearchTerm] = useState('');
    const [showInput, setShowInput] = useState(true);
    const criarPedidoRef = useRef();
    const abrirOcorrenciaRef = useRef();
    const atribuirRef = useRef();
    const [abrirMapa, setAbrirMapa] = useState(false);
    const refTimeout = useRef(null)
    const [mapaMobile, setMapaMobile] = useState(false)
    const [mapaDesktop, setMapaDesktop] = useState(false)
    const [showMapaMobile, setShowMapaMobile] = useState();
    const [mostrarModalContratar, setMostrarModalContratar] = useState(false);
    const usuarioPlano = useSelector(state => state.config.usuarioPlano);
    const visualizou = useSelector(state => state.utils.visualizouTreinamento);
    const sizeAtual = useSelector(state => state.utils?.sizeAtual);
    const refPainelLateral = useRef(null);
    const [expandedGroups, setExpandedGroups] = useState({});
    const tipoDeUsuario = useSelector(state => state.config?.tipoDeUsuario);
    const actionPedido = useSelector(state => state.utils?.criarPedido);
    const [keySequence, setKeySequence] = useState('');
    const response = useSelector((state) => state.config.solicitarEntregaParceira);
    const [mensagensNaoLidas, setMensagensNaoLidas] = useState([]);
    const [showEmLista, setShowEmLista] = useState(false);
    const [loadingXpick, setLoadingXpick] = useState(true);
    const desatribuir = useSelector(state => state.criarPedido?.desatribuir);
    const atribuir = useSelector(state => state.criarPedido?.atribuir);
    const cancelar = useSelector(state => state.criarPedido?.cancelar);
    const empresasPick = useSelector(state => state.config?.empresasPick?.dados?.lista);
    const atualizarEmAndamento = useSelector(state => state.utils?.atualizarEmAndamento);
    const { showError, showSuccess } = useSnackbarContext();
    const template = useSelector(state => state.config?.template?.template);
    const [showConfetti, setShowConfetti] = useState(false);
    const [isResizing, setIsResizing] = useState(false)
    const params = useParams()

    const windowHeight = window.navigator?.screen?.height;
    const windowWidth = window.navigator?.screen?.width;

    useEffect(() => {
        if (params.redirect === 'redirect') {
            setTimeout(() => { setShowConfetti(true) }, 2000);
            setTimeout(() => setShowConfetti(false), 12000)
        }
    }, [])

    useEffect(() => {
        if (!template) return;
        const { faviconUrl, nome, whitelabelUrl } = template;
        const favicon = document.getElementById("dynamic-favicon") as HTMLLinkElement;
        const title = document.getElementById("dynamic-title") as HTMLTitleElement;
        const descriptionMeta = document.querySelector('meta[name="description"]') as HTMLMetaElement;
        if (descriptionMeta && nome) {
            descriptionMeta.content = nome;
        }
        const titleMeta = document.querySelector('meta[property="og:title"]') as HTMLMetaElement;
        if (titleMeta && nome) {
            titleMeta.content = `${nome}`;
        }
        const dynamicUrl = document.querySelector('meta[property="og:url"]') as HTMLMetaElement;
        if (dynamicUrl && whitelabelUrl) {
            dynamicUrl.content = `https://${whitelabelUrl}`;
        }
        const metaImage = document.querySelector('meta[property="og:image"]') as HTMLMetaElement;
        if (metaImage && faviconUrl) {
            metaImage.content = faviconUrl;
        }
        if (favicon && faviconUrl && title) {
            favicon.href = faviconUrl;
            title.innerText = `${nome}`;
        }
    }, [template]);

    useEffect(() => {
        if (response?.data) {
            showSuccess({
                message: response?.data.mensagem,
            })
            dispatch(setAtualizarEmAndamento())
        } else if (response.error) {
            showError({
                message: response.error,
            })
        }
        return () => {
            dispatch(limparEntregaParceira())
        }
    }, [response?.data, response.error]);

    const ehFreemium = useMemo(() => {
        return usuarioPlano === PLANO_FREEMIUM;
    }, [usuarioPlano]);

    const ehBrendi = useMemo(() => {
        return usuarioPlano === PLANO_BRENDI;
    }, [usuarioPlano]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            setKeySequence(prev => prev + event.key);
            if (keySequence.endsWith('toggledevmode')) {
                dispatch(toggleDevMode())
                setKeySequence('');
            }
            /* if (event.key === 'w') {
                setShowEmLista((prevShowEmLista) => !prevShowEmLista);
            } */ if (keySequence.length > 20) {
                setKeySequence(prev => prev.slice(-20));
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [keySequence]);

    const toggleGroup = (groupName) => {
        console.log('toggleGroup', groupName);
        setExpandedGroups(prevState => ({
            ...prevState,
            [groupName]: !prevState[groupName]
        }));
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCallbackFiltros = (filtrosAtualizados) => {
        setFiltros(filtrosAtualizados);
    };

    const aplicarFiltros = (pedido) => {
        let filtroStatus = true;
        let filtroEntregador = true;
        let filtroPedido = true;
        let filtroEmpresa = true;
        if (filtros.status) {
            filtroStatus = pedido.situacao === unSanitizeSituacao(filtros.status);
        }
        if (filtros.entregador) {
            filtroEntregador = pedido.entregadorUsuarioID === filtros?.entregador;
        }
        if (filtros.pedidos) {
            filtroPedido = pedido.codigoQuatro === filtros.pedidos.replace('#', '');
        }
        if (filtros.empresas) {
            filtroEmpresa = pedido?.empresaID === filtros.empresas; // TODO: Filtrar por id
        }
        return filtroStatus && filtroEntregador && filtroPedido && filtroEmpresa;
    };

    const filteredPedidos = emAndamento?.filter((pedido) => {
        const searchTermLower = searchTerm.toLowerCase();
        const matchesCodigo = pedido.codigoQuatro.toLowerCase().includes(searchTermLower);
        const matchesCliente = pedido.clienteNome?.toLowerCase().includes(searchTermLower);
        const matchesEntregador = pedido.entregadorUsuarioNome?.toLowerCase().includes(searchTermLower);

        return (matchesCodigo || matchesCliente || matchesEntregador) && aplicarFiltros(pedido);
    });

    // function getUserLocation() {
    //     return new Promise((resolve, reject) => {
    //         if (!navigator.geolocation) {
    //             reject(new Error("Geolocation is not supported by this browser."));
    //         } else {
    //             navigator.geolocation.getCurrentPosition(
    //                 (position) => {
    //                     const { latitude, longitude } = position.coords;
    //                     resolve({ latitude, longitude });
    //                 },
    //                 (error) => {
    //                     reject(new Error("Unable to retrieve your location. Error: " + error.message));
    //                 }
    //             );
    //         }
    //     });
    // }

    // useEffect(() => {
    //     getUserLocation()
    //         .then(({ latitude, longitude }) => {
    //             setPosition({ latitude, longitude });
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }, []);

    const empresasIds = [];
    empresasIds.push(usuario.id);
    const { paginaAtual, selecionarPagina } = usePagina();
    useEffect(() => {
        selecionarPagina('Painel');
        if (empresasPick?.length > 0) return
        dispatch(listarEmpresasPick({ empresaIDs: empresasIds, filtrarPorTags: false, pagina: 1, paginacao: -1 }));
    }, []);

    const recuperarMensagens = () => {
        if (!usuario) {
            return;
        }
        const chatIdentificador = `${pickSelecionada?.pickID}`;
        const dataLimite = new Date(new Date().setHours(new Date().getHours() - 24));
        const q = query(
            collection(db, 'chatMensagens'),
            where('dataCadastro', '>', dataLimite),
            where('pickID', '==', chatIdentificador),
            where('lido', '==', false),
            orderBy('dataCadastro')
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const mensagens = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            const naoLidas = mensagens.filter((mensagem) => !mensagem.lido && mensagem.remetenteUsuarioID !== usuario?.id).length;
            setMensagensNaoLidas(naoLidas);
        });
        return unsubscribe;
    };

    useEffect(() => {
        recuperarMensagens();
    }, [pickSelecionada, usuario])

    useEffect(() => {
        const listagemInterval = () => {
            dispatch(emAndamentoStart({ empresaID: usuario.id }));
            dispatch(listarEntregadoresRequest(empresasIds));
        }

        let interval = setInterval(listagemInterval, 15000);

        if (atualizarEmAndamento === true) {
            console.log('atualizarEmAndamento');
            clearInterval(interval);
            interval = setInterval(listagemInterval, 15000);
            listagemInterval();
            dispatch(setAtualizarEmAndamento())
        }

        return () => clearInterval(interval);
    }, [usuario.id, atualizarEmAndamento]);

    const abrir = (abrirQual, pedido) => {
        if (pedido) {
            setPedidoStatus(pedido);
            if (pedido.toString() === 'true') {
                selecionarPedido(null);
            } else {
                selecionarPedido(pedido);
            }
        }
        if ((usuarioPlano === PLANO_FREEMIUM)
            && abrirQual !== 'entregadores'
            && abrirQual !== 'abrirMapa'
            && abrirQual !== 'videos'
            && (abrirQual !== 'criarPedido')
            && abrirQual !== 'addFiltros'
            && abrirQual !== 'abrirParceira'
            && abrirQual !== 'atribuirPedido') {
            setMostrarModalContratar(true);
            return;
        }
        if ((usuarioPlano === PLANO_BRENDI)
            && abrirQual !== 'entregadores'
            && abrirQual !== 'abrirMapa'
            && abrirQual !== 'videos'
            && abrirQual !== 'atribuirPedido'
            && abrirQual !== 'criarPedido'
            && abrirQual !== 'addFiltros'
            && abrirQual !== 'abrirChatLateral'
            && abrirQual !== 'abrirParceira') {
            setMostrarModalContratar(true);
            return;
        }
        const states = {
            addFiltros: false,
            abrirOcorrencia: false,
            atribuirPedido: false,
            editarPedido: false,
            criarPedido: false,
            showInput: false,
            showEmAndamento: true,
            abrirParceira: false,
            abrirChatLateral: false,
            abrirAvaliar: false,
            abrirTempoMedio: false,
            abrirMapa: false,
            entregadores: false,
            abrirFinanceiro: false,
            abrirRobo: false,
            abrirTaxaExtra: false,
            videos: false
        };
        const currentValue = eval(abrirQual);
        states[abrirQual] = !currentValue;
        if (!currentValue && abrirQual !== 'addFiltros' && abrirQual !== 'entregadores' && abrirQual !== 'abrirMapa' && abrirQual !== 'videos') {
            states.showEmAndamento = false;
        } else if (currentValue) {
            states.showEmAndamento = true;
        }
        setAddFiltros(states.addFiltros);
        setAbrirOcorrencia(states.abrirOcorrencia);
        setAtribuirPedido(states.atribuirPedido);
        setCriarPedido(states.criarPedido || states.editarPedido);
        setEditarPedido(states.editarPedido);
        setShowInput(states.showInput);
        setAbrirParceira(states.abrirParceira);
        setAbrirChatLateral(states.abrirChatLateral);
        setAbrirAvaliar(states.abrirAvaliar);
        setAbrirTempoMedio(states.abrirTempoMedio);
        setAbrirMapa(states.abrirMapa);
        setShowEmAndamento(states.showEmAndamento);
        setAbrirEntregadores(states.entregadores);
        setAbrirFinanceiro(states.abrirFinanceiro);
        setAbrirRobo(states.abrirRobo);
        setAbrirTaxaExtra(states.abrirTaxaExtra);
        setVideos(states.videos);
    };

    useEffect(() => {
        setShowMapaMobile(isMobile)
        if (isMobile) setMapaMobile(true)
        if (!isMobile) {
            setMapaDesktop(true)
            setAbrirMapa(false)
        }
    }, [isMobile])

    useEffect(() => {
        if (roteando) {
            markersSelecionados?.forEach(pedidoID => {
                const card = emAndamento?.find(e => e.pedidoID === pedidoID);
                if (card && expandedGroups[card.empresa.nome] === false) {
                    toggleGroup(card.empresa.nome);
                }
                const elemento = document.getElementById(pedidoID);
                if (elemento) {
                    elemento.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
        } else if (markerSelecionado) {
            const card = emAndamento?.find(e => e.pedidoID === markerSelecionado);
            if (card && expandedGroups[card.empresa.nome] === false) {
                toggleGroup(card.empresa.nome);
            }
            document.getElementById(markerSelecionado)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            if (refTimeout.current) clearTimeout(refTimeout.current);
            refTimeout.current = setTimeout(() => { selecionarMarker(null) }, 5000);
        }
    }, [roteando, markerSelecionado, markersSelecionados]);

    const handleSizeChange = (newSize) => {
        if (isMobile) return

        dispatch(mudarSize(newSize[0]));
    };

    const ordemSituacao = ['RECEBIDO', 'PRONTO', 'DESPACHADO', 'ACEITO', 'NO_ESTABELECIMENTO', 'EM_ROTA', 'RETORNANDO', 'FINALIZADO'];

    const parseTempoDecorrido = (tempo) => {
        let totalSeconds = 0;

        const timeParts = tempo.split(' ');

        timeParts.forEach(part => {
            if (part.includes('h')) {
                const hours = parseInt(part.replace('h', ''));
                totalSeconds += hours * 3600;
            } else if (part.includes('m')) {
                const minutes = parseInt(part.replace('m', ''));
                totalSeconds += minutes * 60;
            } else if (part.includes('s')) {
                const seconds = parseInt(part.replace('s', ''));
                totalSeconds += seconds;
            }
        });

        return totalSeconds;
    };

    const compare = (a, b) => {
        const situacaoA = ordemSituacao.indexOf(a.situacao);
        const situacaoB = ordemSituacao.indexOf(b.situacao);

        if (situacaoA !== situacaoB) {
            return situacaoA - situacaoB;
        } else {
            const tempoA = parseTempoDecorrido(a.tempoDecorridoCadastro);
            const tempoB = parseTempoDecorrido(b.tempoDecorridoCadastro);

            return tempoB - tempoA;
        }
    };

    const pedidosOrdenados = filteredPedidos?.sort(compare);
    const groupedPedidos = pedidosOrdenados?.reduce((groups, item) => {
        const groupName = item.empresa.nome;
        if (!groups[groupName]) {
            groups[groupName] = [];
        }
        groups[groupName].push(item);
        return groups;
    }, {});

    const empresas = Object.keys(groupedPedidos ?? {});
    const hasMultipleEmpresas = empresas.length > 1 ||
        (Object.keys(filtros).length > 0 && Object.values(filtros).some(value => value !== null));

    useEffect(() => {
        if (empresas.length === 1 && !expandedGroups[empresas[0]]) {
            toggleGroup(empresas[0]);
        }
    }, [filtros]);

    useEffect(() => {
        if (actionPedido === true) {
            abrir('criarPedido');
        }
    }, [actionPedido])

    useEffect(() => {
        const storedValue = localStorage.getItem('@xpick');
        setLoadingXpick(!storedValue);
    }, [emAndamento]);

    const abrirFreemium = () => {
        setMostrarModalContratar(true);
        return
    }


    useEffect(() => {
        if (cancelar?.data?.sucesso) {
            showSuccess({ message: cancelar?.data?.mensagem, duration: 2000 });
        }
        setTimeout(() => {
            dispatch(emAndamentoStart({ empresaID: usuario.id }));
        }, 1000)
    }, [response?.data?.sucesso, actionPedido?.data?.sucesso, desatribuir?.resposta?.sucesso, atribuir?.data?.sucesso, cancelar?.data?.sucesso])

    const filtrosValidos = filtrosReducer
        ? Object.entries(filtrosReducer).filter(([_, value]) => {
            return value !== null && value !== undefined && value !== '' && value !== false;
        }).length
        : 0;


    const slideConfigs = [
        {
            key: 'listaPedidos',
            isOpen: showEmAndamento,
            component: ListaPedidos,
            props: {
                groupedPedidos,
                empresas,
                expandedGroups,
                toggleGroup,
                abrir,
                loadingXpick,
                showEmAndamento,
                searchTerm,
                handleSearchChange,
                filtrosValidos,
                abrirMapa,
                isMobile,
                filteredPedidos,
                mensagensNaoLidas,
                hasMultipleEmpresas,
                abrirFreemium
            },
        },
        { key: 'criarPedido', isOpen: criarPedido, refProp: criarPedidoRef, component: CriarPedido, close: () => abrir('criarPedido', true) },
        { key: 'abrirOcorrencia', isOpen: abrirOcorrencia, refProp: abrirOcorrenciaRef, component: CriarOcorrencia, close: () => abrir('abrirOcorrencia') },
        { key: 'atribuirPedido', isOpen: atribuirPedido, refProp: atribuirRef, component: AtribuirPedido, close: () => abrir('atribuirPedido') },
        { key: 'abrirParceira', isOpen: abrirParceira, component: EntregaParceira, close: () => abrir('abrirParceira') },
        { key: 'abrirChatLateral', isOpen: abrirChatLateral, component: Chat, close: () => abrir('abrirChatLateral') },
        { key: 'abrirAvaliar', isOpen: abrirAvaliar, component: AvaliarPedidos, close: () => abrir('abrirAvaliar') },
        { key: 'abrirTempoMedio', isOpen: abrirTempoMedio, component: TempoMedio, close: () => abrir('abrirTempoMedio') },
        { key: 'abrirFinanceiro', isOpen: abrirFinanceiro, component: Financeiro, close: () => abrir('abrirFinanceiro') },
        { key: 'abrirRobo', isOpen: abrirRobo, component: Robo, close: () => abrir('abrirRobo') },
        { key: 'abrirTaxaExtra', isOpen: abrirTaxaExtra, component: TaxaExtra, close: () => abrir('abrirTaxaExtra') },

    ];

    return (
        <>
            {showConfetti && (
                <ReactConfetti
                    style={{ zIndex: 10000 }}
                    width={windowWidth}
                    height={windowHeight}
                    numberOfPieces={600}
                    tweenDuration={2000}
                    recycle={false}
                />
            )}
            <Box sx={{ height: '100%' }}>
                <Box height="calc(100vh - 52px)">
                    {showEmLista ?
                        <PainelPerformance /> :
                        <Allotment
                            vertical={false}
                            style={{ height: '100%' }}
                            minSize={300}
                            onChange={(newSize) => handleSizeChange(newSize)}
                            onDragStart={() => setIsResizing(true)}
                            onDragEnd={() => setIsResizing(false)}
                            defaultSizes={[sizeAtual, window.innerWidth - sizeAtual]}
                        >
                            <Allotment.Pane
                                style={{ height: '100%' }}
                                priority={'HIGH'}
                                size={isMobile ? 1 : (sizeAtual || 530)}
                            >
                                <Grid2
                                    container
                                    sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                                    id="painelLateral"
                                    ref={refPainelLateral}
                                >
                                    {slideConfigs.map(({ key, isOpen, refProp, component, close, props }) => (
                                        <SlidePanel
                                            key={key}
                                            isOpen={isOpen}
                                            refProp={refProp}
                                            component={component}
                                            close={close}
                                            props={props}
                                        />
                                    ))}
                                </Grid2>
                            </Allotment.Pane>
                            <Allotment.Pane
                                style={{ height: '100%' }}
                                visible={!isMobile}
                                priority={'LOW'}
                            >
                                {mapaDesktop && <Box
                                    sx={{
                                        position: 'relative',
                                        display: !showMapaMobile ? 'block' : 'none',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: isResizing ? 'flex' : 'none',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: theme.palette.background.paper,
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            zIndex: 1
                                        }}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <AspectRatioIcon color="disabled" />
                                            <Typography variant="body2" color="grey">Arraste para redimensionar</Typography>
                                        </Stack>
                                    </Box>
                                    <MapHome
                                        // position={position}
                                        abrirAtribuirPedido={() => abrir('atribuirPedido', null, 'do mapa desktop')}
                                        abrirChatLateral={() => abrir('abrirChatLateral')}
                                    />

                                </Box>}
                            </Allotment.Pane>
                        </Allotment>}
                </Box>
                {mapaMobile &&
                    <div
                        style={{
                            display: (showMapaMobile && abrirMapa) ? 'block' : 'none',
                            height: '100vh',
                            width: '100%',
                            borderRadius: '0px',
                            overflow: 'hidden',
                            position: 'absolute',
                            boxSizing: 'border-box',
                            zIndex: 1500,
                            top: '0px',
                            padding: '10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        <IconButtonFechar
                            onClick={() => abrir('abrirMapa')}
                            sx={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                zIndex: 10000,
                                backgroundColor: '#fff',
                                '&:hover': {
                                    color: '#fff',
                                    borderColor: theme.palette.primary.main,
                                    backgroundColor: theme.palette.primary.main,
                                }
                            }}
                        />
                        <MapHome
                            // propPosition={position}
                            abrirAtribuirPedido={() => abrir('atribuirPedido', null, 'do mapa')}
                            styleProps={{ borderRadius: '10px' }}
                            abrirChatLateral={() => abrir('abrirChatLateral')}
                        />
                    </div>}
                {(isMobile ? showEmAndamento : true)
                    ? (
                        <Stack
                            useFlexGap
                            sx={{
                                gap: '.5rem',
                                flexDirection: 'column-reverse',
                                zIndex: 1001,
                                position: 'fixed',
                                bottom: 80,
                                right: isMobile ? 10 : 20,
                                bgColor: 'red',
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}
                        >
                            {(tipoDeUsuario !== 'Representante' && tipoDeUsuario !== 'LICENCIADO') && <ModalEntregadores abrirEntregadores={entregadores} setAbrirEntregadores={setAbrirEntregadores} />}
                            {ehFreemium || ehBrendi &&
                                <Button
                                    sx={{
                                        width: '56px',
                                        height: '56px',
                                        backgroundColor: '#000',
                                        '&:hover': { backgroundColor: 'lightgray' },
                                        padding: 0,

                                    }}
                                    onClick={() => abrir('videos')}
                                >
                                    <SmartDisplay sx={{ color: 'white' }} />
                                </Button>}
                            {ehFreemium || ehBrendi &&
                                <Button
                                    sx={{
                                        width: '56px',
                                        height: '56px',
                                        backgroundColor: 'secondary.main',
                                        '&:hover': { backgroundColor: 'lightgray' },
                                        padding: 0,
                                    }}
                                    onClick={() => abrir('info')}
                                >
                                    <Box
                                        component="img"
                                        src={promo}
                                        alt="Promo"
                                        sx={{ width: '28px', height: '26px' }}
                                    />
                                </Button>}
                        </Stack>
                    )
                    : null
                }
                <ModalContratarPlanos open={mostrarModalContratar} onClose={() => setMostrarModalContratar(false)} />
                <ModalBoasVindas open={!visualizou && usuarioPlano === PLANO_FREEMIUM} setAbrirEntregadores={setAbrirEntregadores} />
                <ModalVideos open={videos} onClose={() => setVideos(false)} />
                <ModalFiltros
                    visible={addFiltros}
                    onClose={() => abrir('addFiltros')}
                    callback={handleCallbackFiltros}
                />
            </Box >
        </>
    );


}

export default Painel;