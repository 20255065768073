import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ModalHandlePontuacao from 'src/components/Pontuacao/ModalHandlePontuacao';
import TabelaPontuacao from 'src/components/Pontuacao/TabelaPontuacao';
import { DynamicDataContainer } from 'src/ds'
import { DynamicDataContainerRef } from 'src/ds/components/DynamicDataContainer';
import { RootState } from 'src/store/reducers';

const Pontuacao: React.FC = () => {
    const pontuacao = useSelector((state: RootState) => state.entregadores.pontuacao.data?.dados);
    const loading = useSelector((state: RootState) => state.entregadores.pontuacao.loading);
    const hookForm = useForm();
    const cardRef = useRef<DynamicDataContainerRef>(null);
    const [modalCadastrar, setModalCadastrar] = useState(false);

    useEffect(() => {
        hookForm.reset({
            paginacao: 20,
            pagina: 1,
            ordenacaoOrdem: '',
            ordenacaoCampo: '',
        });
    }, [hookForm])

    function refreshTable() {
        cardRef.current?.onSubmit();
    }


    return (
        <>
            <DynamicDataContainer
                ref={cardRef}
                hookForm={hookForm}
                cardFormFiltrosProps={{
                    dispatchMethods: {
                        actionName: 'pontuacaoStart'
                    },
                    fields: [
                        { label: "Buscar", name: "filtros", nameFiltro: 'nome', ehFiltro: true, tipoFiltro: 'TEXTO' },
                    ],
                    loading: loading,
                }}
                sections={[
                    {
                        title: "Pontuação",
                        label: "tabela",
                        ...(pontuacao?.lista && pontuacao?.lista?.length > 0)
                            ? {
                                tableNavigation: {
                                    indiceFinal: pontuacao?.indiceFinal,
                                    indiceTotal: pontuacao?.total,
                                    indiceInicial: pontuacao?.indiceFinal,
                                    ultimaPagina: pontuacao?.ultimaPagina
                                },
                                extraButtons: [{
                                    label: 'Cadastrar',
                                    action: () => setModalCadastrar(true),
                                }],
                            } : undefined,
                        component: <TabelaPontuacao
                            hookForm={hookForm}
                            onSubmit={() => cardRef.current?.onSubmit()}
                            fezRequisicao={cardRef.current?.fezRequisicao}
                            callback={refreshTable}
                        />,
                    }
                ]}
            />
            {modalCadastrar && (
                <ModalHandlePontuacao
                    open={modalCadastrar}
                    onClose={() => setModalCadastrar(false)}
                    callback={refreshTable}
                />
            )}
        </>
    )
}

export default Pontuacao