import { Box, Typography, useTheme, IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import circleDollar from "../../assets/icons/circle-dollar.svg";
import handCircleDollar from "../../assets/icons/hand-holding-circle-dollar.svg";
import sackDollar from "../../assets/icons/sack-dollar.svg";
import motorcycle from "../../assets/icons/motorcycle.svg";
import TabelaPedidos from "../../components/Pedidos/TabelaPedidos";
import DashboardPedidos from "../../components/Pedidos/DashboardPedidos";
import dayjs from "dayjs";
import { Field } from "../../ds/components/CardFormFiltros";
import { RootState } from "../../store/reducers";
import { dinheiro } from "../../ds/common";
import { listarVeiculosStart } from "src/store/reducers/escalas";
import { ContainerLoading, DynamicDataContainer } from "src/ds";
import { listarTiposEmpresas } from "src/store/reducers/empresa";
import { listarTipoDeVeiculoStart } from "src/store/reducers/config";
import { listaEntregadoresStart } from "src/store/reducers/entregador";
import { taxaExtraStart } from "src/store/reducers/taxaExtra";
import { useForm } from "react-hook-form";
import { FiltrosCampo } from "src/dtos/getFiltros";
import { exportarMeusPedidosStart, exportarRelatorioGeralStart } from "src/store/reducers/pedidos";
import { useSnackbarContext } from "src/context/SnackbarContext";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useResolvedPath } from "react-router-dom";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const RelatorioGeral = ({ openDash }: { openDash?: boolean }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { showError } = useSnackbarContext();
    const [isAllOpen, setIsAllOpen] = useState(false);

    const defaultValues = {
        empresaID: '',
        tipoEmpresaID: '',
        paginacao: '20',
        pagina: '1',
        filtros: '',
        titulos: '',
        variaveis: '',
        formato: '',
        considerados: '',
        reentrega: '',
        dtInicialFinalizado: dayjs().subtract(1, 'day').format('DD/MM/YYYY 00:00'),
        dtFinalFinalizado: dayjs().format('DD/MM/YYYY 23:59'),
    };

    const hookForm = useForm();

    useEffect(() => {
        hookForm.reset(defaultValues)
    }, []);

    const { watch, getValues } = hookForm;
    const cardRef = useRef<DynamicDataContainerRef>(null)
    const url = useResolvedPath('');

    useEffect(() => {
        if (!cardRef.current) return
        if (openDash || url.pathname === '/Home/Dashboard') {
            cardRef.current.changeSection('dashboard');
        } else if (url.pathname === '/Pedido/Index') {
            cardRef.current.changeSection('pedidos');
        }
    }, [url, cardRef, openDash])

    const tipoUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);
    const ehRepresentante = tipoUsuario === 'Representante';
    const tiposVeiculo = useSelector((state: RootState) => state.config?.tiposDeVeiculos?.dados?.lista);
    const taxasExtra = useSelector((state: RootState) => state.taxaExtra?.lista?.data?.dados?.lista);
    const formasPagamento = useSelector((state: RootState) => state.config?.formaDePagamento);
    const pedidosDados = useSelector((state: RootState) => state.pedidos?.listaPedidos?.data?.dados);
    const pedidosError = useSelector((state: RootState) => state.pedidos?.listaPedidos?.error);
    const loadingPedidos = useSelector((state: RootState) => state.pedidos?.listaPedidos.loading);
    const meusPedidos = useSelector((state: RootState) => state.pedidos?.meusPedidos?.data?.dados);
    const meusPedidosError = useSelector((state: RootState) => state.pedidos?.meusPedidos.error);
    const loadingMeusPedidos = useSelector((state: RootState) => state.pedidos?.meusPedidos.loading);
    const loadingExportarRelatorioGeral = useSelector((state: RootState) => state.pedidos.exportarRelatorioGeral.loading);
    const loadingExportarMeusPedidos = useSelector((state: RootState) => state.pedidos.exportarMeusPedidos.loading);
    const tipoDeUsuarioLoading = useSelector((state: RootState) => state.config?.tipoDeUsuarioLoading);
    const primeiraRenderizacao = useRef(true);

    useEffect(() => {
        if (primeiraRenderizacao.current === true) {
            dispatch(listarVeiculosStart());
            dispatch(listarTiposEmpresas({
                paginacao: -1,
                pagina: -1,
                ordenacaoCampo: '',
                ordenacaoOrdem: '',
                filtros: ''
            }))
            dispatch(listarTipoDeVeiculoStart({
                paginacao: -1,
                pagina: -1,
                ordenacaoCampo: '',
                ordenacaoOrdem: '',
                filtros: ''
            }))
            dispatch(listaEntregadoresStart({
                paginacao: -1,
                pagina: -1,
                ordenacaoCampo: '',
                ordenacaoOrdem: '',
                filtros: ''
            })
            )
            primeiraRenderizacao.current = false; return
        };

        dispatch(taxaExtraStart({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }))
    }, [primeiraRenderizacao]);

    const atualizarDashboard = cardRef.current?.atualizarDispatch ?? false;

    useEffect(() => {
        if (pedidosError) {
            showError({
                message: pedidosError,
            })
        }
    }, [pedidosError])

    useEffect(() => {
        if (meusPedidosError) {
            showError({
                message: meusPedidosError,
            });
        }
    }, [meusPedidosError])

    const formFieldsGenerico: Field[] = [
        { label: 'Código do pedido', name: 'codigoExterno' },
        { label: 'Entregador', name: 'entregadorUsuarioID', type: 'entregador' },
        { label: 'Data inicial (Finalizado)', name: 'dtInicialFinalizado', type: 'dateTime' },
        { label: 'Data final (Finalizado)', name: 'dtFinalFinalizado', type: 'dateTime' },
        //* Apenas Licenciado
        // { 
        //     label: 'Tipo de empresa',
        //     value: 'tipoEmpresaID',
        //     type: 'customSelect',
        //     options: tipoEmpresas,
        //     valueKey: 'tipoEmpresaID',
        //     labelKey: 'nome',
        // },
        { label: 'Data inicial', name: 'dtInicial', type: 'dateTime' },
        { label: 'Data final', name: 'dtFinal', type: 'dateTime' },
        { label: 'Aplicação de origem', name: 'aplicacaoOrigemNome' },
        { label: 'Taxa extra aplicada', name: 'taxaExtraID', type: 'customSelect', options: taxasExtra, valueKey: 'taxaExtraID', labelKey: 'nome' },
        {
            label: 'Tipo de veículo',
            name: 'tipoVeiculoID',
            type: 'customSelect',
            options: tiposVeiculo,
            valueKey: 'tipoVeiculoID',
            labelKey: 'nome',
        },
        { label: 'Forma de pagamento', name: 'formaPagamentoID', type: 'customSelect', options: formasPagamento, valueKey: 'formaPagamentoID', labelKey: 'nome' },
        {
            label: 'Tipo de fatura',
            name: 'tipoFatura',
            type: 'select',
            options: [
                { label: 'Pré-pago', value: '1' },
                { label: 'Pós-pago', value: '0' },
            ]
        },
        { label: 'Empresa', name: 'empresaID', type: 'autocompleteEmpresa' },
        {
            label: 'Tipo de pedido',
            name: 'tipoPedidoCodigo',
            type: 'autocompleteMultiple',
            options: [
                { label: 'Pedido', value: '0' },
                { label: 'Diária', value: '1' },
                { label: 'Vale', value: '2' },
                { label: 'Bônus', value: '3' },
                { label: 'Antecipação', value: '4' },
                { label: 'Administrativo', value: '5' },
                { label: 'Outros', value: '99' },
            ],
            fullWidth: true,
        },
        {
            label: 'Situação',
            name: 'situacao',
            type: 'autocompleteMultiple',
            labelKey: 'label',
            valueKey: 'value',
            options: [
                { label: 'Recebido', value: 'RECEBIDO' },
                { label: 'Pronto', value: 'PRONTO' },
                { label: 'Despachado', value: 'DESPACHADO' },
                { label: 'Aceito', value: 'ACEITO' },
                { label: 'No estabelecimento', value: 'NO_ESTABELECIMENTO' },
                { label: 'Em rota', value: 'EM_ROTA' },
                { label: 'Retornando', value: 'RETORNANDO' },
                { label: 'Finalizado', value: 'FINALIZADO' },
                { label: 'Cancelado', value: 'CANCELADO' },
            ],
            fullWidth: true,
        },
    ]

    const formFieldsRepresentante: Field[] = [
        {
            label: 'Empresa',
            name: 'empresaID',
            type: 'autocompleteEmpresa',
            rules: { required: 'Empresa obrigatória' }
        },
        { label: 'Código do pedido', name: 'codigoExterno', ehFiltro: true, tipoFiltro: 'TEXTO', igual: true },
        {
            label: 'Entregador',
            name: 'entregadorUsuario.nome',
            ehFiltro: true,
            filtroCustomizado(nome, valor) {
                return `${nome}.ToLower().Contains("${valor}")`
            },
            tipoFiltro: 'TEXTO'
        },
        {
            label: 'Data inicial (Finalizado)',
            name: 'dtInicialFinalizado',
            type: 'dateTime',
            ehFiltro: true,
            tipoFiltro: 'DATA_HORA',
            nameFiltro: 'dataFinalizado',
            maiorQue: true,
            igual: true
        },
        {
            label: 'Data final (Finalizado)',
            name: 'dtFinalFinalizado',
            type: 'dateTime',
            ehFiltro: true,
            tipoFiltro: 'DATA_HORA',
            nameFiltro: 'dataFinalizado',
            menorQue: true
        },
        {
            label: 'Data inicial',
            name: 'dtInicialCadastro',
            type: 'dateTime',
            ehFiltro: true,
            tipoFiltro: 'DATA_HORA',
            nameFiltro: 'dataCadastro',
            maiorQue: true,
            igual: true
        },
        {
            label: 'Data final',
            name: 'dtFinalCadastro',
            type: 'dateTime',
            ehFiltro: true,
            tipoFiltro: 'DATA_HORA',
            nameFiltro: 'dataCadastro',
            menorQue: true
        },
        {
            label: 'Situação',
            name: 'situacao',
            type: 'autocompleteMultiple',
            labelKey: 'label',
            valueKey: 'value',
            fullWidth: true,
            options: [
                { label: 'Finalizado', value: 'FINALIZADO' },
                { label: 'Cancelado', value: 'CANCELADO' },
            ],
            ehFiltro: true,
            tipoFiltro: "SELECT_MULTIPLE",
            filtroCustomizado: (nome, valor)=>{
                const valores = valor;
                console.log(valores)
                let filtro = '';
                if (Array.isArray(valores) && valores.length > 0) {
                    let valoresString = valores
                    .map((valor) => `${nome}.ToString().Equals("${valor.value}")`)
                    .join(" || ");
                  filtro += `(${valoresString})`;
                } else {
                  filtro += nome + '.Contains("' + valor.toString() + '")';
                }
                console.log(filtro)
                return filtro
            }
        },
        {
            label: 'Tipo',
            name: 'tipoPedidoCodigo',
            type: 'autocompleteMultiple',
            labelKey: 'label',
            valueKey: 'value',
            options: [
                { label: 'Pedido', value: 'PEDIDO' },
                { label: 'Diária', value: 'DIÁRIA' },
                { label: 'Vale', value: 'VALE' },
                { label: 'Bônus', value: 'BÔNUS' },
                { label: 'Antecipação', value: 'ANTECIPAÇÃO' },
                { label: 'Administrativo', value: 'ADMINISTRATIVO' },
                { label: 'Outros', value: 'OUTROS' },
            ],
            ehFiltro: true,
            tipoFiltro: 'SELECT_MULTIPLE',
            filtroCustomizado: (nome, valor)=>{
                const valores = valor;
                console.log(valores)
                let filtro = '';
                if (Array.isArray(valores) && valores.length > 0) {
                    let valoresString = valores
                    .map((valor) => `${nome}.ToString().Equals("${valor.value}")`)
                    .join(" || ");
                  filtro += `(${valoresString})`;
                } else {
                  filtro += nome + '.Contains("' + valor.toString() + '")';
                }
                console.log(filtro)
                return filtro
            }
        },
    ]

    const consideradosFiltro: FiltrosCampo = {
        nome: 'entraFatura',
        valor: watch('considerados'),
        filtroCustomizado(_nome, _valor) {
            return `entraFatura`
        },
    }

    const reentregaFiltro: FiltrosCampo = {
        nome: 'reentrega',
        valor: watch('reentrega'),
        filtroCustomizado(_nome, _valor) {
            return `origemPedido != null`
        },
    }

    const payloadExportar = getValues()

    const menuOptions = [
        {
            label: 'Exportar',
            action: () => {
                ehRepresentante
                    ? dispatch(exportarMeusPedidosStart(payloadExportar))
                    : dispatch(exportarRelatorioGeralStart(payloadExportar))
            },  
            disabled: ehRepresentante ? !watch('empresaID') : false
        }
    ]

    const cardTabelaTotalData = [{
        srcIcon: circleDollar,
        altIcon: ehRepresentante ? 'Ícone Valor Total de Taxas' : "Ícone Valor Total de Cobrado",
        bgcolor: theme.palette.green[600],
        texts: ehRepresentante ? {
            title1: 'VALOR TOTAL DE TAXAS',
            subtitle1: meusPedidos?.totalCobrado ? dinheiro(meusPedidos?.totalCobrado) : 'R$0,00',
        } : {
            title1: 'VALOR TOTAL COBRADO',
            subtitle1: pedidosDados?.totalCobrado ? dinheiro(pedidosDados?.totalCobrado) : 'R$0,00',
            title2: 'VALOR MÉDIO POR PEDIDO',
            subtitle2: pedidosDados?.totalCobrado ? dinheiro((pedidosDados?.totalCobrado / pedidosDados?.total).toFixed(2)) : 'R$0,00',
        }
    }, {
        srcIcon: ehRepresentante ? sackDollar : handCircleDollar,
        altIcon: ehRepresentante ? 'Ícone Total Faturado' : "Ícone Valor Total de Custo",
        bgcolor: ehRepresentante ? theme.palette.yellow[600] : theme.palette.red[600],
        texts: ehRepresentante ? {
            title1: 'VALOR TOTAL DE VENDAS',
            subtitle1: meusPedidos?.totalFaturado ? dinheiro(meusPedidos?.totalFaturado) : 'R$0,00',
        } : {
            title1: 'VALOR TOTAL DE CUSTO',
            subtitle1: pedidosDados?.totalCusto ? dinheiro(pedidosDados?.totalCusto) : 'R$0,00',
            title2: 'VALOR MÉDIO POR PEDIDO',
            subtitle2: pedidosDados?.totalCusto ? dinheiro((pedidosDados?.totalCusto / pedidosDados?.total).toFixed(2)) : 'R$0,00',
        }
    }, {
        srcIcon: ehRepresentante ? motorcycle : sackDollar,
        altIcon: ehRepresentante ? 'Ícone Pedidos Finalizados' : "Ícone Valor Total de Vendas",
        bgcolor: ehRepresentante ? theme.palette.blue[600] : theme.palette.yellow[600],
        texts: ehRepresentante ? {
            title1: 'PEDIDOS FINALIZADOS',
            subtitle1: meusPedidos?.pedidosFinalizados ? meusPedidos?.pedidosFinalizados : '0',
        } : {
            title1: 'VALOR TOTAL DE VENDAS',
            subtitle1: pedidosDados?.totalFaturado ? dinheiro(pedidosDados?.totalFaturado) : 'R$0,00',
            title2: 'TICKET MÉDIO',
            subtitle2: pedidosDados?.totalFaturado ? dinheiro((pedidosDados?.totalFaturado / pedidosDados?.total).toFixed(2)) : 'R$0,00',
        }
    }, {
        srcIcon: ehRepresentante ? handCircleDollar : motorcycle,
        altIcon: ehRepresentante ? 'Ícone Pedidos Cancelados' : "Ícone Valor Total de Pedidos",
        bgcolor: ehRepresentante ? theme.palette.red[600] : theme.palette.blue[600],
        texts: ehRepresentante ? {
            title1: 'PEDIDOS CANCELADOS',
            subtitle1: meusPedidos?.pedidosCancelados ? meusPedidos.pedidosCancelados : '0',
        } : {
            title1: 'PEDIDOS',
            subtitle1: pedidosDados?.total ? pedidosDados?.total : '0',
        }
    }]

    if (tipoDeUsuarioLoading) return (
        <Box sx={{ height: 'calc(100vh - 52px)' }}><ContainerLoading /></Box>
    )

    return (
        <DynamicDataContainer
            hookForm={hookForm}
            ref={cardRef}
            cardFormFiltrosProps={ehRepresentante
                ? {
                    fields: formFieldsRepresentante,
                    reentrega: true,
                    reentregaFiltro: reentregaFiltro,
                    considerados: true,
                    consideradosFiltro: consideradosFiltro,
                    dispatchMethods: { actionName: 'listarMeusPedidosStart' },
                    loading: loadingMeusPedidos,
                } : {
                    fields: formFieldsGenerico,
                    reentrega: true,
                    considerados: true,
                    solicitadoParceiro: true,
                    alocados: true,
                    dispatchMethods: { actionName: 'listarPedidosGeral' },
                    loading: loadingPedidos,
                }}
            cardTabelaTotalProps={{
                cardData: cardTabelaTotalData
            }}
            sections={[
                {
                    label: 'Pedidos',
                    tableNavigation: ehRepresentante
                        ? {
                            indiceInicial: meusPedidos?.indiceInicial,
                            indiceFinal: meusPedidos?.indiceFinal,
                            indiceTotal: meusPedidos?.total,
                            ultimaPagina: meusPedidos?.ultimaPagina,
                            requiredFields: ['empresaID'],
                            podeTodos: false,
                        } : {
                            indiceInicial: pedidosDados?.indiceInicial,
                            indiceFinal: pedidosDados?.indiceFinal,
                            indiceTotal: pedidosDados?.total,
                            ultimaPagina: pedidosDados?.ultimaPagina,
                            podeTodos: false,
                        },
                    menu: {
                        options: menuOptions,
                        loading: ehRepresentante ? loadingExportarMeusPedidos : loadingExportarRelatorioGeral,
                    },
                    extraActions: (cardRef.current?.fezRequisicao || ((ehRepresentante ? meusPedidos?.lista : pedidosDados?.lista)?.length || 0) > 0) ? [
                        <Tooltip arrow placement='top' title={isAllOpen ? "Minimizar todos os pedidos" : "Expandir todos os pedidos"}>
                            <IconButton
                                color={isAllOpen ? 'secondary' : 'primary'}
                                onClick={() => { setIsAllOpen((prev) => !prev) }}
                            >
                                {isAllOpen ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </Tooltip>
                    ] : undefined,
                    component: <TabelaPedidos
                        pedidos={ehRepresentante ? meusPedidos?.lista : pedidosDados?.lista}
                        loading={ehRepresentante ? loadingMeusPedidos : loadingPedidos}
                        fezRequisicao={cardRef.current?.fezRequisicao}
                        tipoUsuario={tipoUsuario}
                        hookForm={hookForm}
                        onSubmit={cardRef.current?.onSubmit}
                        isAllOpen={isAllOpen}
                    />,
                },
                {
                    label: 'Dashboard',
                    updateOnExchange: true,
                    component: ehRepresentante
                        ? watch('empresaID') === ''
                            ? (
                                <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                                    <Typography variant="notFound" > Selecione uma empresa para visualizar o dashboard. </Typography>
                                </Box>
                            ) : (
                                <DashboardPedidos
                                    formMethods={hookForm}
                                    loading={loadingMeusPedidos}
                                    atualizar={atualizarDashboard}
                                    fezRequisicao={cardRef.current?.fezRequisicao}
                                />
                            )
                        : (
                            <DashboardPedidos
                                formMethods={hookForm}
                                loading={loadingPedidos}
                                atualizar={atualizarDashboard}
                                fezRequisicao={cardRef.current?.fezRequisicao}
                            />
                        ),
                },
            ]}
        />
    );
};

export default RelatorioGeral;
